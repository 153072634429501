import {Component, inject, OnInit} from "@angular/core";
import {Platform} from "@core/service/platform";
import {Config} from "@core/service/config";
import {GoogleAnalyticsService} from "@core/module/google/analytics";
import {AutorunService} from "@core/service/autorun/autorun.service";
import {AppService} from "./app.service";
import {OrdersDialogService} from "@shared/service/api/orders/dialog";
import {RouteService} from "@shared/service/route/route.service";
import {LazyLoadedService} from "@core/service/lazy-loaded/lazy-loaded.service";
import {Dialog} from "@angular/cdk/dialog";
import {DialogActiveOrdersDesktop, DialogActiveOrdersMobile} from "@shared/module_v2/dialog/ui/active-orders/active-orders";
import {Resize2Service} from "@core/service/resize2/resize2.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {filter, take} from "rxjs/operators";
import {LoadingCommonState} from "@state/loading-common.state";
import {ActiveOrdersService} from "@core/service/active-orders/active-orders.service";
import {toObservable} from "@angular/core/rxjs-interop";
import * as Sentry from "@sentry/angular";
import {DeviceTokenService} from "@core/service/device-token/device-token.service";
import {Meta} from "@angular/platform-browser";
import {MobileService} from "@core/service/mobile";

@Component({
    selector: "app-megapteka",
    styleUrls: ["app.component.less"],
    templateUrl: "./app.component.html",
    providers: [Config, GoogleAnalyticsService, AppService, OrdersDialogService, RouteService, LazyLoadedService],
})
export class AppComponent implements OnInit {
    private dialog = inject(Dialog);
    private router = inject(Router);
    private loadingCommonState = inject(LoadingCommonState);
    private activeOrdersService = inject(ActiveOrdersService);
    private deviceTokenService = inject(DeviceTokenService);
    private meta = inject(Meta);
    private mobileService = inject(MobileService);
    private listActiveOrders = toObservable(this.activeOrdersService.list);

    constructor(
        private _platform: Platform,
        private _autorunService: AutorunService,
        private _resizeService: Resize2Service,
    ) {
        this._autorunService.onInit();
        if (_platform.browser) {
            setTimeout(() => {
                let content = "width=device-width, initial-scale=1, maximum-scale=1.3";
                if (this.mobileService.isApple) {
                    content = "width=device-width, initial-scale=1, maximum-scale=1";
                }
                this.meta.updateTag({
                    name: "viewport",
                    content: content,
                });
            }, 4000);

            this.router.events
                .pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd))
                .subscribe((event) => {
                    if (event instanceof NavigationStart) {
                        this.loadingCommonState.start();
                    }
                    if (event instanceof NavigationEnd) {
                        this.loadingCommonState.stop();
                    }
                });
        }
    }

    ngOnInit() {
        Sentry.setTag("device-token", this.deviceTokenService.get());
        this.checkoutOrder();
    }

    /**
     * Проверка активных заказов
     * После 10000ms начинает проверку.
     */
    checkoutOrder() {
        if (this._platform.browser) {
            setTimeout(() => {
                if (
                    this.router.url.indexOf("/check") !== -1 ||
                    this.router.url.indexOf("/basket") !== -1 ||
                    this.router.url.indexOf("/lk/orders") !== -1
                ) {
                    return;
                }
                this.listActiveOrders.pipe(take(1)).subscribe((list) => {
                    if (list && list.length > 0) {
                        import("@shared/module_v2/dialog/ui/active-orders/active-orders.component").then((res) => {
                            let options = new DialogActiveOrdersDesktop().options;
                            if (this._resizeService.resize$.value.mobile) {
                                options = new DialogActiveOrdersMobile().options;
                            }

                            options.data = list;
                            this.dialog.open(res.DialogActiveOrdersComponent, options);
                        });
                    }
                });
            }, 10000);
        }
    }
}
