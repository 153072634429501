export interface AuthTelemetryInPut {
    device_token?: string;
    city_id?: number;
    platform?: number;
    advert_id?: number;
    push_token?: string;
    appmetrica_id?: number;
    yandexmetrica_id?: string;
}
export interface AuthTelemetryOutPut {
    total: number;
}
