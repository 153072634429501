import {inject, Injectable, signal} from "@angular/core";
import {SearchSuggestionsService} from "@shared/service/api/search/suggestions/suggestions.service";
import {SearchSuggestionsOutGet} from "@shared/service/api/search/suggestions";
import {SearchQueriesService} from "@shared/service/api/search/queries/queries.service";
import {SearchQueriesOutGet} from "@shared/service/api/search/queries";
import {forkJoin, mergeMap, Observable, tap} from "rxjs";
import {SearchHeaderHintsData, SearchHeaderHintsStatus} from "@component/ui-request/search-header-hints/search-header-hints";
import {SearchHeaderHintsHistoryService} from "@component/ui-request/search-header-hints/search-header-hints-history.service";
import {debounceTime, map} from "rxjs/operators";

@Injectable({
    providedIn: "any",
})
export class SearchHeaderHintsService {
    public data = signal<SearchHeaderHintsData>({
        status: "start",
        history: null,
        queries: [],
        products: [],
        groups: [],
        tags: [],
    });

    private searchHeaderHintsHistoryService = inject(SearchHeaderHintsHistoryService);
    private searchSuggestionsService = inject(SearchSuggestionsService);
    private searchQueriesService = inject(SearchQueriesService);

    /**
     * Запрос подсказок
     *
     * @param query
     */
    getSuggestions(query: string): Observable<SearchSuggestionsOutGet> {
        return this.searchSuggestionsService.get({query: query}).pipe(
            debounceTime(500),
            tap(({groups, tags, products}) => {
                this.data.set({
                    ...this.data(),
                    ...{
                        groups: groups || [],
                        products: products || [],
                        tags: tags || [],
                    },
                });

                if (products?.length === 0 && tags?.length === 0 && groups?.length === 0) {
                    this.setStatus("not_fount");
                } else {
                    this.setStatus("fount");
                }
            }),
        );
    }

    /**
     * Запрос для получение данных
     *
     * @param query
     */
    fetchData(query?: string) {
        if (this.data().status === "start") {
            if (!query) {
                this.getQueriesAndHistory().subscribe();
            } else {
                this.getSuggestions(query)
                    .pipe(mergeMap(() => this.getQueriesAndHistory(false)))
                    .subscribe();
            }
        } else {
            if (query && query.length > 2) {
                this.getSuggestions(query).subscribe();
            } else {
                this.setStatus("empty");
            }
        }
    }

    /**
     * Получение подсказок + истории, вызываться должен 1 раз
     *
     * @param setStatus
     */
    getQueriesAndHistory(setStatus: boolean = true): Observable<{
        queries: SearchQueriesOutGet["list"];
        history: string[];
    }> {
        return forkJoin({
            queries: this.searchQueriesService.get({count: 5}).pipe(map((value) => value.list)),
            history: this.searchHeaderHintsHistoryService.get(),
        }).pipe(
            tap(({queries}) => {
                this.data.set({
                    ...this.data(),
                    ...{
                        queries,
                        history: this.searchHeaderHintsHistoryService.history,
                    },
                });
                if (setStatus) {
                    this.setStatus("empty");
                }
            }),
        );
    }

    /**
     * Установка статуса загрузки
     * @param status
     */
    setStatus(status: SearchHeaderHintsStatus) {
        this.data.set({...this.data(), ...{status}});
    }

    clear() {
        this.searchHeaderHintsHistoryService.clear();
    }
}
