import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {SearchHeaderHintsHistoryService} from "@shared/module_v2/ui-request/search-header-hints/search-header-hints-history.service";
import {SearchHeaderHintsService} from "@shared/module_v2/ui-request/search-header-hints/search-header-hints.service";
import {SearchSuggestionsOutGet} from "@shared/service/api/search/suggestions";
import {BehaviorSubject, debounce, of, Subscription, timer} from "rxjs";
import {GoogleAnalyticsService} from "@core/module/google/analytics";
import {YandexMetrikaService} from "@core/service/yandex-metrika";
import {SearchQueriesOutGet} from "@shared/service/api/search/queries";
import {fadeInOutAnimation} from "@shared/animation/fadeInOut.animations";
import {CityService} from "@core/service/city";
import {ParametersService} from "@core/service/parameters";

@Component({
    selector: "ma-ui-request-search-header-hints",
    templateUrl: "./search-header-hints.component.html",
    styleUrls: ["./search-header-hints.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SearchHeaderHintsHistoryService],
    animations: [fadeInOutAnimation],
})
export class SearchHeaderHintsComponent implements OnInit, OnDestroy {
    public _query$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public cityCode: string = "";

    private querySubs$ = new Subscription();
    private searchHeaderHintsService = inject(SearchHeaderHintsService);
    private searchHeaderHintsHistoryService = inject(SearchHeaderHintsHistoryService);
    private parametersService = inject(ParametersService);

    @Input() set query(value: string) {
        if (value === null) {
            value = "";
        }
        this._query$.next(value);
    }

    @Output() onClearHistory: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onSearchValue: EventEmitter<string> = new EventEmitter<string>();

    public data = this.searchHeaderHintsService.data.asReadonly();

    constructor(
        private _googleAnalytics: GoogleAnalyticsService,
        private _yandexMetrikaService: YandexMetrikaService,
        private _city: CityService,
    ) {}

    ngOnInit(): void {
        this.cityCode = this._city.data.code;
        this.initSubs();
    }

    ngOnDestroy() {
        this.querySubs$.unsubscribe();
    }

    initSubs() {
        this._query$
            .pipe(
                debounce((value) => {
                    if (this.data().status === "start") {
                        return of(value);
                    }
                    return value.length > 2 ? timer(this.parametersService.parametersData.DELAY_SEARCH_SUGGESTS) : of(value);
                }),
            )
            .subscribe((res) => {
                this.searchHeaderHintsService.fetchData(res);
            });
    }

    clearHistory() {
        this.searchHeaderHintsService.clear();
    }

    onClickProduct(elem: SearchSuggestionsOutGet["products"][0]) {
        this._googleAnalytics.eventClickVirtualSuggestClick();
        this._yandexMetrikaService.reachGoal("suggest_click");
        this._yandexMetrikaService.reachGoal("40248_suggest_click");
        // #51966 > Выбор цветного саджеста/ пункта из истории поиска (Поиск_товаров)
        this._yandexMetrikaService.reachGoal("GL_action_search", {
            type: "Подсказка по введенной строке",
            alt: "product",
            text: elem.name,
        });
        this.setHistory();
        this.onSearchValue.emit(elem.name);
    }

    onClickTag(elem: SearchSuggestionsOutGet["tags"][0]) {
        this.setHistory();
        // #51966 > Выбор цветного саджеста/ пункта из истории поиска (Поиск_товаров)
        this._yandexMetrikaService.reachGoal("GL_action_search", {
            type: "Подсказка по введенной строке",
            alt: "tag",
            text: elem.name,
        });
        this.onSearchValue.emit(elem.name);
    }

    onClickGroup(elem: SearchSuggestionsOutGet["groups"][0]) {
        this.setHistory();
        // #51966 > Выбор цветного саджеста/ пункта из истории поиска (Поиск_товаров)
        this._yandexMetrikaService.reachGoal("GL_action_search", {
            type: "Подсказка по введенной строке",
            alt: "group",
            text: elem.name,
        });
        this.onSearchValue.emit(elem.name);
    }

    onClickHistory(elem: string) {
        // #51966 > Выбор цветного саджеста/ пункта из истории поиска (Поиск_товаров)
        this._yandexMetrikaService.reachGoal("GL_action_search", {
            type: "История поиска",
            text: elem,
        });
        this.onSearchValue.emit(elem);
    }

    onClickTagColor(elem: SearchQueriesOutGet["list"][0]) {
        // #51966 > Выбор цветного саджеста/ пункта из истории поиска (Поиск_товаров)
        this._yandexMetrikaService.reachGoal("GL_action_search", {
            type: "Цветной саджест",
            text: elem.query,
        });
        this.onSearchValue.emit(elem.query);
    }

    setHistory() {
        this.searchHeaderHintsHistoryService.add(this._query$.getValue());
    }

    trackByFn(
        index: number,
        item: SearchSuggestionsOutGet["tags"][0] | SearchSuggestionsOutGet["groups"][0] | SearchSuggestionsOutGet["products"][0],
    ) {
        return item.id;
    }
}
