import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import {fromEvent, Observable, Subscription} from "rxjs";
import {Resize2Service} from "@core/service/resize2/resize2.service";
import {debounceTime, filter, map} from "rxjs/operators";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {UiSearchHeaderService} from "@shared/module_v2/ui/search-header/search-header.service";
import {Platform} from "@core/service/platform";
import {YandexMetrikaService} from "@core/service/yandex-metrika";

@Component({
    selector: "ma-ui-search-header",
    templateUrl: "./search-header.component.html",
    styleUrls: ["./search-header.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchHeaderComponent implements OnInit, OnDestroy {
    @Input() isFloating: boolean = false;
    public isPlaceholderMobile$: Observable<boolean> = new Observable<boolean>();
    public query$ = new Observable<string>();
    public isViewHints: boolean = false;
    public isMouseOver: boolean = false;

    private routerEvents$: Subscription = new Subscription();
    private windowScroll$: Subscription = new Subscription();
    private activatedRouteQueryParams$: Subscription = new Subscription();

    private yandexMetrikaService = inject(YandexMetrikaService);

    @HostListener("mouseenter", ["$event"]) onMouseEnter() {
        this.isMouseOver = true;
    }

    @HostListener("mouseleave", ["$event"]) onMouseLeave() {
        this.isMouseOver = false;
    }

    @ViewChild("input", {static: false}) input: ElementRef<HTMLInputElement>;

    private uiSearchHeaderService = inject(UiSearchHeaderService);
    private platform = inject(Platform);

    constructor(
        private _resize: Resize2Service,
        private _router: Router,
        private _cdr: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.initSubs();
        this.initValue();
    }

    ngOnDestroy() {
        this.routerEvents$.unsubscribe();
        this.activatedRouteQueryParams$.unsubscribe();
        this.windowScroll$.unsubscribe();
    }

    initValue() {
        this.getQ();
    }

    initSubs() {
        this.query$ = this.uiSearchHeaderService.query$;
        this.routerEvents$ = this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
            this.isViewHints = false;
        });
        this.activatedRouteQueryParams$ = this._activatedRoute.queryParams.subscribe(() => {
            this.getQ();
        });

        this.isPlaceholderMobile$ = this._resize.resize$.pipe(map((res) => res.mobile));

        if (this.platform.browser) {
            this.windowScroll$ = fromEvent(window, "scroll")
                .pipe(
                    debounceTime(60),
                    filter(() => {
                        return this.isViewHints === true;
                    }),
                )
                .subscribe(() => {
                    this.isViewHints = false;
                    this._cdr.detectChanges();
                });
        }
    }

    getQ(): void {
        const paramMap = this._activatedRoute.snapshot.queryParamMap;
        this.uiSearchHeaderService.query$.next(paramMap.has("q") ? paramMap.get("q") : "");
    }

    onChangedQuery(query: string) {
        this.uiSearchHeaderService.query$.next(query);
        this.isViewHints = true;
    }

    onClickQuery(str: string) {
        if (str.length >= 2) {
            // #51966 > Подтверждение ручного ввода текста (Поиск_товаров)
            this.yandexMetrikaService.reachGoal("GL_action_search", {
                type: "Ручной ввод текста",
                text: str,
            });
            this._router.navigate(["/search"], {queryParams: {q: str}});
            this.isViewHints = false;
        }
    }

    onClickDelete() {
        this.input.nativeElement.focus();
        this.uiSearchHeaderService.query$.next("");
    }

    onInputBlur() {
        if (this.isMouseOver === false) {
            this.isViewHints = false;
        }
    }

    onInputFocus() {
        this.isViewHints = true;
        // #51966 > Нажатие на поле ввода/ голос/ штрих-код (Переход_в_поиск_товаров)
        this.yandexMetrikaService.reachGoal("GL_focus_search");
    }

    onSearchValue(query: string) {
        this.uiSearchHeaderService.query$.next(query);
    }
}
