import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {environment} from "./environments/environment";
import {BrowserAppModule} from "./app/app.browser.module";
import * as Sentry from "@sentry/angular";

if (environment.production) {
    enableProdMode();
}
Sentry.init({
    environment: environment.production ? "production" : "dev",
    release: environment.sentry.release,
    dsn: environment.sentry.dsn,
    sendDefaultPii: true,
    autoSessionTracking: true,
    maxBreadcrumbs: 200,
    initialScope: {
        tags: {rendering: "csr"},
    },
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: environment.sentry.tracingOrigins,
    sampleRate: environment.sentry.sampleRate,
    ignoreErrors: environment.sentry.ignoreErrors,
    denyUrls: environment.sentry.denyUrls,
    tracesSampleRate: environment.sentry.tracesSampleRate,
});

document.addEventListener(
    "DOMContentLoaded",
    () => {
        platformBrowserDynamic()
            .bootstrapModule(BrowserAppModule)
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log("catch bootstrapModule");
                // eslint-disable-next-line no-console
                console.log(err);
            });
    },
    {
        passive: true,
    },
);
