import {inject, Injectable} from "@angular/core";
import {LsService} from "@core/service/localstorage";
import {Basket, BasketJsonItem} from "./interface";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {Subject} from "rxjs/internal/Subject";
import {Platform} from "@core/service/platform";
import {ActivatedRoute, Router} from "@angular/router";
import {debounceTime, filter, map, switchMap, take} from "rxjs/operators";
import {LogService} from "@core/service/logger";
import {YandexMetrikaService} from "@core/service/yandex-metrika";
import {CityService} from "@core/service/city";
import {OrdersBasketService} from "@shared/service/api/orders/basket";
import {CartOnlineService} from "@shared/service/api/cart/online";
import {UserService} from "@core/service/user";
import {ItemsSumInGet, ItemsSumOutGet, ItemsSumService} from "@shared/service/api/items/sum";
import {BasketItems} from "@model/basket-items";
import {combineLatest, iif, Observable, tap} from "rxjs";

@Injectable({providedIn: "root"})
export class BasketService {
    private readonly STORAGE_NAME = "basket";
    private lsService = inject(LsService);
    private logService = inject(LogService);
    private yandexMetrikaService = inject(YandexMetrikaService);
    private itemsSumService = inject(ItemsSumService);
    private platform = inject(Platform);
    private cityService = inject(CityService);
    private ordersBasketService = inject(OrdersBasketService);
    private cartOnlineService = inject(CartOnlineService);
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);
    private userService = inject(UserService);

    /**
     * Список товаров который у пользователя
     */
    public list$ = new BehaviorSubject<BasketItems[]>([]);

    /**
     * Subject добавление товара в корзину
     */
    public add$: Subject<Basket> = new Subject();

    /**
     * Subject добавление единиц к товару
     */
    public plus$: Subject<Basket> = new Subject();

    /**
     * Subject удаление единиц к товару
     */
    public minus$: Subject<Basket> = new Subject();

    /**
     * Subject обновление единиц у товара
     */
    public update$: Subject<Basket> = new Subject();

    /**
     * Удаление товара из корзины
     */
    public delete$: Subject<number> = new Subject();

    /**
     * Очистка корзины
     */
    public clear$: Subject<void> = new Subject();

    /**
     * Количество товаров в корзине
     */
    public count$: BehaviorSubject<number> = new BehaviorSubject(0);

    /**
     * Сумма товаров в корзине
     */
    public sum$: BehaviorSubject<number> = new BehaviorSubject(0);

    /**
     * Вызывается после того как была синхронизирована корзина
     */
    public updateApi$: BehaviorSubject<void> = new BehaviorSubject(null);
    /**
     *
     * @private
     */
    private updateDataApi$ = new Subject<Basket[]>();

    /**
     * Буфер корзины из STORAGE
     *
     * @private
     */
    private _list = new Map<number, Basket>();

    /**
     * Инициализация корзины
     */
    load() {
        combineLatest([this.cityService.loading$, this.userService.loading$])
            .pipe(
                tap(() => {
                    this.createBufferList();
                }),
                filter(([city, user]) => city === true && user === true),
                take(1),
                switchMap(() => {
                    return this.getInfoBasket(this.userService.isAuth$.value);
                }),
            )
            .subscribe((res) => {
                const resultClear = this.clearItems(res.items, res.online);

                if (this.userService.isAuth$.value) {
                    this._list.clear();
                }
                this.saveList(resultClear.listStorage);
                this.list$.next(resultClear.listApi);
                this.sum$.next(res.sum);

                if (resultClear.listUpdate) {
                    resultClear.listUpdate.forEach((value) => {
                        this.update$.next(value);
                    });
                }

                this.countItemAll();
                this.subsAddAcrossUrl();
            });

        this.updateApiData();

        return true;
    }

    public init() {
        this.subsAuth();
    }

    createBufferList() {
        this.storage.forEach((v) => {
            this.setMap(v.id, v.quantity, v.is_selected);
        });
    }

    /**
     * Запись в Map
     *
     * @param id
     * @param quantity
     * @param is_selected
     *
     * @private
     */
    private setMap(id: number, quantity: number, is_selected: boolean) {
        if (!id) {
            return;
        }

        this._list.set(id, {id, quantity, is_selected});
    }

    /**
     * Удаление из Map
     *
     * @param id
     *
     * @private
     */
    private deleteMap(id: number) {
        if (!id) {
            return;
        }
        this._list.delete(id);
    }

    /**
     * Запись в Map
     *
     * @param list <Basket[]>
     * @private
     */
    private saveList(list: Basket[]) {
        list.reverse().forEach((value) => {
            if (value.quantity === 0) {
                this._list.delete(value.id);
            } else {
                if (this._list.has(value.id)) {
                    if (value.quantity !== null && value.is_selected !== null) {
                        this._list.set(value.id, value);
                    } else if (value.quantity === null) {
                        this._list.set(value.id, {...value, ...{quantity: this._list.get(value.id).quantity}});
                    } else if (value.is_selected === null) {
                        this._list.set(value.id, {...value, ...{is_selected: this._list.get(value.id).is_selected}});
                    }
                } else {
                    this._list = new Map([[value.id, value], ...this._list]);
                }
            }
        });

        this.storage = this.get();
    }

    /**
     * Получение корзины при инициализации сайта
     *
     * @param isAuth<boolean>
     * @param listBasketStorage<Basket[]>
     * @private
     */
    private getInfoBasket(
        isAuth: boolean,
        listBasketStorage: Basket[] = [],
    ): Observable<{
        online: Basket[] | null;
        items: BasketItems[];
        sum: number;
    }> {
        return iif(
            () => isAuth,
            this.syncItemsApi(listBasketStorage).pipe(
                switchMap((onlineBasket) => {
                    return this.getItemsApi(onlineBasket.list).pipe(
                        map((getItemsApi) => {
                            return {
                                online: onlineBasket.list,
                                items: getItemsApi.list,
                                sum: getItemsApi.sum,
                            };
                        }),
                    );
                }),
            ),
            this.getItemsApi(this.get()).pipe(
                map((getItemsApi) => {
                    return {
                        online: null,
                        items: getItemsApi.list,
                        sum: getItemsApi.sum,
                    };
                }),
            ),
        );
    }

    /**
     * Обновление информации о товаре
     * @private
     */
    private updateApiData() {
        const buffer = new Map<number, Basket>();
        this.updateDataApi$
            .pipe(
                map((list) => {
                    // Складываем получаемые значение в буфер
                    list.forEach((newValue) => {
                        const tmp = {
                            id: newValue.id,
                            quantity: newValue.quantity,
                            is_selected: newValue.is_selected,
                        };
                        buffer.set(tmp.id, tmp);
                    });
                    this.saveList(list);
                    return buffer;
                }),
                debounceTime(500),
                switchMap((list) => {
                    const bufferList = Array.from(list, ([name, value]) => value);
                    return this.getInfoBasket(this.userService.isAuth$.value, bufferList);
                }),
                tap(() => {
                    buffer.clear();
                }),
            )
            .subscribe((res) => {
                const resultClear = this.clearItems(res.items, res.online);
                this.saveList(resultClear.listStorage);
                this.list$.next(resultClear.listApi);
                this.sum$.next(res.sum);
                this.countItemAll();
                this.updateApi$.next();

                if (resultClear.listUpdate) {
                    resultClear.listUpdate.forEach((value) => {
                        this.update$.next(value);
                        if (value.quantity === 0) {
                            this.delete$.next(value.id);
                        }
                    });
                }

                this.countItemAll();
            });
    }

    /**
     * Событие авторизации
     *
     * @private
     */
    private subsAuth() {
        this.userService.login$.subscribe(() => {
            this.updateDataApi$.next(this.get());
        });
        this.userService.logout$.subscribe(() => {
            this.clear();
        });
    }

    /**
     * Для ручного вызова синхронизации
     */
    public getSyncBasket() {
        this.updateDataApi$.next([]);
    }

    /**
     * Корзина из buffer
     *
     * @returns {Basket[]}
     */
    public get(): Basket[] {
        if (this.platform.browser) {
            return Array.from(this._list.values());
        }
        return [];
    }

    /**
     * Корзина из buffer активных товаров
     *
     * @returns {Basket[]}
     */
    public getActive(): Basket[] {
        if (this.platform.browser) {
            const res = [];
            this._list.forEach((value) => {
                if (value.is_selected) {
                    res.push(value);
                }
            });
            return res;
        }
        return [];
    }

    /**
     * Список активных товаров под заказ (Метод на будующие)
     */
    public getActiveStock() {
        if (this.platform.browser) {
            const arr: Basket[] = [];
            this.get().forEach((value) => {
                if (value.is_selected) {
                    arr.push(value);
                }
            });
            return arr;
        }
        return [];
    }

    /**
     *  Количества всех товаров в корзине
     */
    public countItemAll(): number {
        const list = this.get();
        let count = 0;
        list.forEach((value) => {
            count += value.quantity;
        });

        this.count$.next(count);
        return count;
    }

    /**
     * Количества товара в корзине
     *
     * @param id
     */
    public countItem(id: number): number {
        const tmp = this.getItem(id);
        if (!tmp) {
            return 0;
        }
        return tmp.quantity || 0;
    }

    /**
     * Получение данных о товарах
     *
     * @private
     */
    private getItemsApi(list: Basket[]): Observable<ItemsSumOutGet> {
        const params: ItemsSumInGet = {
            items: list.map((res) => {
                return {
                    id: res.id,
                    quantity: res.quantity,
                };
            }),
        };
        return this.itemsSumService.get(params);
    }

    clearItems(
        listItemApi: BasketItems[],
        listOnline: Basket[],
    ): {
        listApi: BasketItems[];
        listStorage: Basket[];
        listUpdate?: Map<number, Basket>;
    } {
        if (this.userService.isAuth$.value) {
            return this.clearItemsAuth(listItemApi, listOnline);
        }
        return this.clearItemsNotAuth(listItemApi);
    }

    /**
     * Метод очистки товаров которые попали в корзину случайно
     * Для не авторизованных пользователей
     *
     * @param listItemApi
     * @private
     */
    private clearItemsNotAuth(listItemApi: BasketItems[]): {
        listApi: BasketItems[];
        listStorage: Basket[];
    } {
        const listStorage: Basket[] = this.get();
        const listStorageNew: Basket[] = [];

        if (!listItemApi) {
            return {
                listApi: listItemApi,
                listStorage: listStorage,
            };
        }

        listStorage.forEach((elemStorage) => {
            const f = listItemApi.find((elemApi) => elemApi.id === elemStorage.id);
            if (f) {
                listStorageNew.push({
                    id: f.id,
                    quantity: f.quantity,
                    is_selected: elemStorage.is_selected,
                });
            }
        });

        return {
            listApi: listItemApi,
            listStorage: listStorageNew,
        };
    }

    /**
     * Метод очистки товаров и синхронизации корзины для авторизованного пользователя
     *
     * @param listItemApi
     * @param listOnline
     * @private
     */
    private clearItemsAuth(
        listItemApi: BasketItems[] = [],
        listOnline: Basket[] = [],
    ): {
        listApi: BasketItems[];
        listStorage: Basket[];
        listUpdate: Map<number, Basket>;
    } {
        const arrUpdate = new Map<number, Basket>();

        // Добавляем/Обновляем товары, которые пришли с Апи
        listOnline.forEach((value) => {
            if (this._list.has(value.id)) {
                if (this._list.get(value.id).quantity !== value.quantity) {
                    arrUpdate.set(value.id, value);
                }
                if (this._list.get(value.id).is_selected !== value.is_selected) {
                    arrUpdate.set(value.id, value);
                }
            } else {
                arrUpdate.set(value.id, value);
            }
        });

        // Ищем удаленные товары или не существующие
        this._list.forEach((value) => {
            const f = listOnline.find((res) => res.id === value.id);
            if (f === undefined) {
                arrUpdate.set(value.id, {id: value.id, quantity: 0, is_selected: value.is_selected});
                this._list.delete(value.id);
            }
        });
        return {
            listApi: listItemApi,
            listStorage: listOnline,
            listUpdate: arrUpdate,
        };
    }

    /**
     * Синхронизация корзины онлайн
     * @private
     */
    private syncItemsApi(list: Basket[]) {
        return this.cartOnlineService.post({items: list});
    }

    /**
     * Проверка товара в корзине
     *
     * @param {number} _item
     * @return {boolean}
     */
    public isItem(_item: number): boolean {
        const item = this.getItem(_item);
        return item !== null;
    }

    /**
     * Товар из корзины
     *
     * @param {number} item
     * @returns {Basket}
     */
    public getItem(item: number): Basket | null {
        if (this.platform.browser) {
            if (this._list.has(item)) {
                return this._list.get(item);
            }
        }
        return null;
    }

    /**
     * Перезапись всей корзины
     *
     * @param {Basket[]} list
     */
    public set(list: Basket[]) {
        this.updateDataApi$.next(list);
    }

    /**
     * Перезапись всей корзины
     *
     * @param id number
     * @param is_selected boolean
     */
    public changeActive(id: number, is_selected: boolean) {
        if (!id || typeof is_selected !== "boolean") {
            return;
        }
        const item = this.getItem(id);
        this.updateDataApi$.next([{id: item.id, is_selected: is_selected, quantity: null}]);
    }

    /**
     * Добавления товара/количества в корзину
     *
     * @param {number} item
     * @param {number} quantity
     */
    public countPlus(item: number, quantity: number = 1) {
        const obj = this.getItem(item);
        const tmp: Basket = {
            id: item,
            quantity: quantity,
            is_selected: true,
        };

        if (obj !== null) {
            tmp.quantity = obj.quantity + quantity;
            tmp.is_selected = null;
            this.updateDataApi$.next([tmp]);
            this.plus$.next(tmp);
            this.update$.next(tmp);
        } else {
            this.updateDataApi$.next([tmp]);
            this.add$.next(tmp);
            this.update$.next(tmp);
        }
    }

    /**
     * Удаления товара/количества из корзины
     *
     * @param {number} item
     * @param {number} quantity
     */
    public countMinus(item: number, quantity: number = 1) {
        const obj = this.getItem(item);
        const tmp: Basket = {
            id: item,
            quantity: obj.quantity,
            is_selected: obj.is_selected,
        };
        if (obj) {
            if (tmp.quantity > quantity) {
                tmp.quantity = tmp.quantity - quantity;
                tmp.is_selected = null;
                this.updateDataApi$.next([tmp]);
                this.minus$.next(tmp);
                this.update$.next(tmp);
            } else {
                this.deleteItem(item);
                this.delete$.next(item);
                obj.quantity = 0;
                this.update$.next(obj);
                this.yandexMetrikaService.reachGoalByIdentifier("removeFromBasket");
            }
        }
    }

    /**
     * Очистка корзины
     */
    public clear() {
        const list = this._list;
        const listNew: Basket[] = [];
        list.forEach((value, key) => {
            listNew.push({
                id: key,
                quantity: 0,
                is_selected: false,
            });
        });

        this.updateDataApi$.next(listNew);
        this.clear$.next();
        this.count$.next(0);
        this.sum$.next(0);
        this.list$.next([]);
    }

    /**
     * Удаления товара
     *
     * @param {number} item
     *
     */
    public deleteItem(item: number) {
        if (!item) {
            return;
        }
        this.updateDataApi$.next([{id: item, quantity: 0, is_selected: null}]);
    }

    /**
     * Добавление товаров через урл
     *
     * @private
     */
    private subsAddAcrossUrl() {
        this.activatedRoute.queryParams
            .pipe(
                filter((params) => {
                    return params["basket_add"];
                }),
            )
            .subscribe((params) => {
                this.ordersBasketService.get({token: params["basket_add"]}).subscribe((res) => {
                    res.items.forEach((v) => {
                        this.countPlus(v.id, v.quantity);
                    });
                    this.router.navigate(["/basket"], {relativeTo: this.activatedRoute, queryParams: {basket_add: null}});
                });
            });
    }

    /**
     * Получение даных из хранилище
     *
     * @private
     */
    private get storage(): Basket[] {
        try {
            const json = this.lsService.get(this.STORAGE_NAME);
            if (json.length === 0) {
                return [];
            }

            const list = JSON.parse(json) as BasketJsonItem[];
            return list.reduce((acc: Basket[], v) => {
                if (isFinite(v.i) && isFinite(v.c)) {
                    let isActive = true;
                    if (v.a !== undefined) {
                        isActive = !(v.a === "f");
                    }
                    acc.push({
                        id: v.i,
                        quantity: v.c,
                        is_selected: isActive,
                    });
                }
                return acc;
            }, []);
        } catch (e) {
            this.logService.error("Получение данных из storage корзины", e);
            return [];
        }
    }

    /**
     * Запись в хранилище
     *
     * @param list
     * @private
     */
    private set storage(list: Basket[]) {
        try {
            const save: BasketJsonItem[] = list.map((v) => {
                return {
                    i: v.id,
                    c: v.quantity,
                    a: v.is_selected ? "t" : "f",
                };
            });
            this.lsService.set(this.STORAGE_NAME, JSON.stringify(save));
        } catch (e) {
            this.logService.error("Сохранение в storage корзины", e);
        }
    }
}
