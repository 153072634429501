import {AppRoutingModule} from "./app.routing";
import {RouterModule} from "@angular/router";
import {APP_ID, APP_INITIALIZER, LOCALE_ID, NgModule} from "@angular/core";
import {AppComponent} from "./app.component";
import {CookieService} from "ngx-cookie-service";
import {CoreModule} from "@core/module";
import localeDeAt from "@angular/common/locales/ru";
import {registerLocaleData} from "@angular/common";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpInterceptor} from "@core/interceptor/http.interceptor";
import {HttpErrorInterceptor} from "@core/interceptor/http-error.interceptor";
import {DeviceTokenService} from "@core/service/device-token/device-token.service";
import {ResizeService} from "@core/service/resize/resize.service";
import {BreakpointObserver} from "@angular/cdk/layout";
import {MobileService} from "@core/service/mobile";
import {Platform} from "@core/service/platform";
import {Resize2Service} from "@core/service/resize2/resize2.service";
import {HttpCachingInterceptor} from "@core/interceptor/http-caching.interceptor";
import {NgxPageScrollModule} from "ngx-page-scroll";
import {HttpMockInterceptor} from "@core/interceptor/http-mock.interceptor";
import {ParametersService} from "@core/service/parameters";
import {ServiceWorkerModule} from "@angular/service-worker";
import {LoadingCommonComponent} from "@common/shared/component/loading/common/common.component";
import {HttpCancelRepeatInterceptor} from "@core/interceptor/http-cancel-repeat-interceptor.interceptor";
import {PopupYourCityService} from "@common/shared/service/popup-your-city/popup-your-city.service";
import {SmartBannerService} from "@common/shared/service/smart-banner/smart-banner.service";
import {RefererService} from "@common/shared/service/referer/referer.service";
import {provideClientHydration} from "@angular/platform-browser";

registerLocaleData(localeDeAt);

@NgModule({
    declarations: [AppComponent],
    imports: [
        RouterModule,
        AppRoutingModule,
        CoreModule,
        NgxPageScrollModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: true,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000",
        }),
        LoadingCommonComponent,
    ],
    providers: [
        provideClientHydration(),
        {provide: APP_ID, useValue: "ma"},
        CookieService,
        {
            provide: APP_INITIALIZER,
            useFactory: (_deviceTokenService: DeviceTokenService) => () => _deviceTokenService.set(),
            deps: [DeviceTokenService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (_parametersService: ParametersService) => () => _parametersService.load(),
            deps: [ParametersService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (popupYourCityService: PopupYourCityService) => () => popupYourCityService.load(),
            deps: [PopupYourCityService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (smartBannerService: SmartBannerService) => () => smartBannerService.load(),
            deps: [SmartBannerService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (refererService: RefererService) => () => refererService.load(),
            deps: [RefererService],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: "ru-RU",
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpCachingInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpCancelRepeatInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpMockInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (_resizeService: ResizeService) => () => _resizeService.load(),
            deps: [ResizeService, BreakpointObserver, MobileService, Platform],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (_resizeService: Resize2Service) => () => _resizeService.load(),
            deps: [Resize2Service, BreakpointObserver, MobileService, Platform],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
